import React, { useRef } from 'react';
import './Sidebar.css';

const Sidebar = ({
  isOpen, toggleSidebar, categories, addCategory, removeCategory,
  selectCategory, importCategory, exportCategory, currentCategory
}) => {
  const fileInputRef = useRef();

  const handleAddCategory = () => {
    const name = prompt('Enter category name:');
    if (name) {
      addCategory(name);
    }
  };

  const handleImportCategory = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = JSON.parse(e.target.result);
        if (data.name && data.items) {
          importCategory(data);
        }
        // Clear the input value to allow importing the same file again
        event.target.value = null;
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
        <h2>Cross-Off Lists</h2>
      </div>
      <ul className="category-list">
        <li>
          <button className="add-category-btn" onClick={handleAddCategory}>+ Add Category</button>
        </li>
        {Object.keys(categories).map((name) => (
          <li key={name}>
            <button
              className={`category-btn ${currentCategory === name ? 'selected' : ''}`}
              onClick={() => selectCategory(name)}
            >
              {name}
            </button>
            <button className="delete-btn" onClick={() => removeCategory(name)}>
              &#128465;
            </button>
          </li>
        ))}
        <li className="separator-line"></li>
        <li>
          <button className="import-export-btn" onClick={() => fileInputRef.current.click()}>Import Category</button>
        </li>
        <li>
          <button className="import-export-btn" onClick={exportCategory}>Export Current Category</button>
        </li>
        <li className="separator-line"></li>
        <li>
          <button className="import-export-btn">List Bazar</button>
        </li>
      </ul>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImportCategory}
      />
    </div>
  );
};

export default Sidebar;
