import React, { useState, useRef } from 'react';
import './UploadForm.css';

const UploadForm = ({ addItem }) => {
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleAddItem = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newItem = {
          img: e.target.result,
          description,
          url,
          crossed: false
        };
        addItem(newItem);
        setDescription('');
        setUrl('');
        setFile(null);
        setIsDragging(false);
        fileInputRef.current.value = null;  // Clear the file input
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <div className="upload-form">
      <div
        className={`drop-area ${isDragging ? 'dragging' : ''}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={() => fileInputRef.current.click()}
      >
        {file ? (
          <p>{file.name}</p>
        ) : (
          <p>Drag & Drop a file here or click to select</p>
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <input
        type="text"
        placeholder="Add a description (optional)"
        value={description}
        onChange={handleDescriptionChange}
      />
      <input
        type="text"
        placeholder="Add a URL (optional)"
        value={url}
        onChange={handleUrlChange}
      />
      <button className="add-item-btn" onClick={handleAddItem}>Add</button>
    </div>
  );
};

export default UploadForm;
