import React from 'react';
import UploadForm from './UploadForm';
import ItemBox from './ItemBox';
import './MainContent.css';

const MainContent = ({
  toggleSidebar, currentCategory, addItem, items,
  toggleCrossItem, removeItem, isSidebarOpen,
  errorMessage, isDarkMode, toggleDarkMode, searchTerm, onSearchChange
}) => {
  return (
    <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <header className="header">
        <h2>
          <button className="toggle-btn desktop-only" onClick={toggleSidebar}>&#9776;</button>
          {currentCategory}
        </h2>
        <div className="header-buttons">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={onSearchChange}
            className="search-input"
          />
          <button className="dark-mode-toggle" onClick={toggleDarkMode}>
            {isDarkMode ? '🌞' : '🌙'}
          </button>
          <button className="toggle-btn mobile-only" onClick={toggleSidebar}>&#9776;</button>
        </div>
      </header>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <main>
        <UploadForm addItem={addItem} />
        <div className="separator"></div> {/* Visual separator */}
        <div className="items-container">
          {items && items.map((item, index) => (
            <ItemBox
              key={item.id}
              item={item}
              toggleCrossItem={() => toggleCrossItem(item.id)}
              removeItem={() => removeItem(index)}
            />
          ))}
        </div>
      </main>
    </div>
  );
};

export default MainContent;
