import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import './App.css';
import { saveCategories, loadCategories } from './indexedDB';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(true);  // Sidebar open by default
  const [categories, setCategories] = useState({});
  const [currentCategory, setCurrentCategory] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const storedCategories = await loadCategories();
      setCategories(storedCategories);

      // Automatically select the top tab (first category)
      const firstCategory = Object.keys(storedCategories)[0];
      if (firstCategory) {
        setCurrentCategory(firstCategory);
      }
    };
    fetchCategories();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const saveCategoriesToDB = async (updatedCategories) => {
    await saveCategories(updatedCategories);
    setCategories(updatedCategories);
  };

  const addCategory = (name) => {
    const uniqueName = getUniqueCategoryName(name, categories);
    const newCategories = { ...categories, [uniqueName]: [] };
    saveCategoriesToDB(newCategories);

    // Automatically select the new category
    setCurrentCategory(uniqueName);
  };

  const removeCategory = (name) => {
    const { [name]: _, ...newCategories } = categories;
    saveCategoriesToDB(newCategories);
    const remainingCategories = Object.keys(newCategories);

    // Automatically select the top tab (first category) after deletion
    setCurrentCategory(remainingCategories[0] || '');
  };

  const addItem = (item) => {
    if (!currentCategory) {
      setErrorMessage('Please select a category before adding an item.');
      return;
    }
    setErrorMessage('');
    const newItem = { ...item, id: uuidv4() }; // Add unique identifier
    const newCategories = {
      ...categories,
      [currentCategory]: [...categories[currentCategory], newItem]
    };
    saveCategoriesToDB(newCategories);
  };

  const removeItem = (index) => {
    const newCategories = { ...categories };
    newCategories[currentCategory].splice(index, 1);
    saveCategoriesToDB(newCategories);
  };

  const selectCategory = (name) => {
    setCurrentCategory(name);
    setErrorMessage('');
  };

  const importCategory = (category) => {
    const uniqueName = getUniqueCategoryName(category.name, categories);
    const importedItems = category.items.map(item => ({ ...item, id: uuidv4() })); // Ensure unique IDs for imported items
    const newCategories = { ...categories, [uniqueName]: importedItems };
    saveCategoriesToDB(newCategories);
    setCurrentCategory(uniqueName);
  };

  const exportCategory = () => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({
      name: currentCategory,
      items: categories[currentCategory]
    }));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${currentCategory}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const toggleCrossItem = (id) => {
    const newCategories = { ...categories };
    const itemIndex = newCategories[currentCategory].findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      newCategories[currentCategory][itemIndex].crossed = !newCategories[currentCategory][itemIndex].crossed;
      saveCategoriesToDB(newCategories);
    }
  };

  const getUniqueCategoryName = (name, categories) => {
    let uniqueName = name;
    let count = 1;
    while (categories[uniqueName]) {
      uniqueName = `${name} (${count})`;
      count++;
    }
    return uniqueName;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredItems = categories[currentCategory]?.filter(item =>
    item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`App ${isSidebarOpen ? 'sidebar-open' : ''} ${isDarkMode ? 'dark-mode' : ''}`}>
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        categories={categories}
        addCategory={addCategory}
        removeCategory={removeCategory}
        selectCategory={selectCategory}
        importCategory={importCategory}
        exportCategory={exportCategory}
        currentCategory={currentCategory}
      />
      <MainContent
        toggleSidebar={toggleSidebar}
        currentCategory={currentCategory}
        addItem={addItem}
        items={filteredItems}
        toggleCrossItem={toggleCrossItem}
        removeItem={removeItem}
        isSidebarOpen={isSidebarOpen}  // Pass sidebar state to MainContent
        errorMessage={errorMessage}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
      />
    </div>
  );
}

export default App;
