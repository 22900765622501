import { openDB } from 'idb';

const DATABASE_NAME = 'CrossOffApp';
const DATABASE_VERSION = 1;
const STORE_NAME = 'categories';

export const initDB = async () => {
  const db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
  return db;
};

export const saveCategories = async (categories) => {
  const db = await initDB();
  await db.put(STORE_NAME, categories, 'categories');
};

export const loadCategories = async () => {
  const db = await initDB();
  const categories = await db.get(STORE_NAME, 'categories');
  return categories || { Default: [] };
};
